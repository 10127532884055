<template>
  <section>
    <div class="bg-1 -below"></div>
    <div class="bg-2 -below"></div>
    <div class="bg-3 -below"></div>
    <v-container>
      <v-row>
        <v-col
          cols="12"
          md="4"
          offset-md="7"
          class="title-container mb-13"
        >
          {{title}}
        </v-col>
      </v-row>
    </v-container>
    <div class="slider ">
      <div ref="swiper" class="swiper-container -above">
        <div class="swiper-wrapper">
          <article
            v-for="(step, i) in stepsArray"
            :key="i"
            class="swiper-slide"
          >
            <v-container class="slide-content">
              <v-row>
                <v-col
                  cols="12"
                  md="6"
                  class="d-flex align-center"
                  style="min-height: 412px;"
                >
                  <v-img
                    v-if="!!step.image"
                    ref="image"
                    class="image"
                    width="100%"
                    height="400px"
                    :contain="$vuetify.breakpoint.mdAndUp"
                    :lazy-src="getLazySrc(step.image)"
                    :src="optimizedImageURL(step.image, { width: 800, quality: 95 })"
                    :aspect-ratio="getAspectRatio(step.image)"
                    transition="fade-transition"
                  />
                  <VideoPlayer
                    v-else-if="step.video"
                    :video="step.video"
                    :poster="step.poster"
                    @playing="$events.$emit('slider-video-playing')"
                    ref="video"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="5"
                  offset-md="1"
                  lg="4"
                  class="step-content"
                >
                  <header>
                    <h1 class="h2" :class="gradientClass">{{step.title}}</h1>
                    <span class="index text-small">{{ getSlideIndex(i) }}</span>
                  </header>
                  <MarkdownBlock
                    class="description"
                    linkify
                    tag="div"
                    :inline="false"
                    :text="step.description"
                  />
                </v-col>
              </v-row>
            </v-container>
          </article>
        </div>
      </div>
      <v-container class="slider-controls -above">
        <v-row>
          <v-col
            cols="12"
            md="9"
            offset-md="2"
            class="d-flex align-center justify-center"
          >
            <SliderProgress
              ref="sliderProgress"
              :type="progressType"
              :slides="stepsArray.length"
            />
            <SliderArrows
              class="slider-arrow"
              :nextDisabled="nextDisabled"
              :prevDisabled="prevDisabled"
              @prev="slidePrev()"
              @next="slideNext()"
            />
          </v-col>
        </v-row>
      </v-container>
    </div>
  </section>
</template>

<script>
import { getLazySrc, optimizedImageURL, getAspectRatio } from '@/services/OptimizedImage'
import { Swiper } from 'swiper'
// import { gsap } from 'gsap'
import { MarkdownBlock } from '@monogrid/vue-lib'
import GradientClass from '@/mixins/GradientClass'
import SliderProgress from '@/atoms/SliderProgress'
import SliderArrows from '@/atoms/SliderArrows'
import VideoPlayer from '@/molecules/VideoPlayer'

export default {
  name: 'sliderStep',
  mixins: [GradientClass],
  props: {
    title: {
      type: String
    },
    steps: {
      type: Array
    },
    progressType: {
      type: String,
      default: 'slide' // 'full' or 'slide'
    }
  },
  components: { MarkdownBlock, SliderProgress, SliderArrows, VideoPlayer },
  data () {
    const steps = this.steps.map(step => {
      if (step.content) {
        const image = { ...step.content.image }
        image.meta = JSON.parse(image.metaData)
        image.meta.ratio = image.meta['image-width'] / image.meta['image-height']
        return {
          title: step.title,
          description: step.content.description,
          image
        }
      } else {
        return {
          ...step,
          description: step.text,
          poster: step.poster ? step.poster.id : null
        }
      }
    })

    return {
      stepsArray: Object.freeze(steps),
      nextDisabled: false,
      prevDisabled: true
    }
  },
  mounted () {
    this.$events.$on('modular-video-playing', () => {
      this.$refs.video[this.swiper.realIndex].pause()
    })

    this.$nextTick(() => {
      // this.createTimelines()
      this.tls = []
      this.currentTransitionSpeed = 0
      this.swiper = new Swiper(this.$refs.swiper, {
        centeredSlides: true,
        slidesPerView: 1,
        freeMode: true,
        freeModeSticky: true,
        touchStartPreventDefault: false,
        watchSlidesProgress: true,
        grabCursor: true,
        on: {
          setTransition: this.setTransition,
          setTranslate: this.setTranslate,
          reachBeginning: () => {
            this.prevDisabled = true
            this.nextDisabled = false
          },
          reachEnd: () => {
            this.prevDisabled = false
            this.nextDisabled = true
          },
          fromEdge: this.fromEdge,
          activeIndexChange: this.onChangeSlide
        }
      })
    })
  },
  beforeDestroy () {
    this.swiper && this.swiper.destroy(true, false)
  },
  methods: {
    getLazySrc,
    optimizedImageURL,
    getAspectRatio,
    slidePrev () {
      this.swiper && this.swiper.slidePrev()
    },
    slideNext () {
      this.swiper && this.swiper.slideNext()
    },
    getSlideIndex (i) {
      return i < 9 ? `0${i + 1}` : i + 1
    },
    getTransitionSpeed () {
      const transitionSpeed = this.currentTransitionSpeed
      // reset the variable for future calls
      this.currentTransitionSpeed = 0
      return transitionSpeed
    },
    setTransition (swiper, transitionSpeed) {
      this.currentTransitionSpeed = transitionSpeed
    },
    setTranslate (swiper, wrapperTranslate) {
      const duration = this.getTransitionSpeed() / 1000
      this.$refs.sliderProgress.setProgress(swiper.progress, duration)
    },
    fromEdge (swiper) {
      if (!swiper.isBeginning && !swiper.isEnd) {
        this.prevDisabled = false
        this.nextDisabled = false
      }
    },
    onChangeSlide (swiper) {
      if (this.$refs.video && this.$refs.video[swiper.previousIndex]) {
        this.$refs.video[swiper.previousIndex].pause()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.bg-1,
.bg-2,
.bg-3 {
  position: absolute;
  left: 50%;
  top: 50%;
  width: rem(1000px);
  height: rem(1000px);
  background: $bg-emo-gradient-3;

  transform: translate(-50%, -50%);
  pointer-events: none;
}

.bg-1 {
  background: $bg-emo-gradient-1;

  transform: translate(-40%, -20%);
}

.bg-3 {
  background: $bg-emo-gradient-3;
  left: auto;
  right: 0;
  top: 0;
  transform: translate(50%, -50%);
}

.title-container {
  border-bottom: 1px solid $color-detail;
}

.slide-content {
  box-sizing: border-box;
}

.slider {
  position: relative;
}

.slider-controls {
  padding-top: rem(40px);

  @include breakpoint('md-and-up') {
    padding-top: rem(80px);
  }
  @include breakpoint('sm-and-down') {
    position: absolute;
    top: rem(400px);
  }
}

header {
  position: relative;

  margin-top: rem(64px);
  margin-bottom: rem(16px);
  @include breakpoint('md-and-up') {
    margin-top: rem(0);
    margin-bottom: rem(32px);
  }
}

.image {
  &::v-deep .v-image__image {
    // background-position: center center !important;
    @include breakpoint('md-and-up') {
      background-position: right center !important;
    }
  }
}

.description {
  @include breakpoint('sm-and-down') {
    max-height: rem(180px);
    padding: rem(20px 0);
    overflow: auto;
    /* stylelint-disable */
    /* autoprefixer: ignore next */
    -webkit-mask-image: -webkit-linear-gradient(rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 1) 20px, rgba(0, 0, 0, 1) calc(100% - 20px), rgba(0, 0, 0, 0) 100%);
    mask-image: linear-gradient(rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 1) 20px, rgba(0, 0, 0, 1) calc(100% - 20px), rgba(0, 0, 0, 0) 100%);
    /* stylelint-enable */
  }
}

.index {
  position: absolute;
  top: 100%;
  left: 0%;
  display: block;
  transform: translate(-120%, 0%);
}

.slider-arrow {
  width: rem(170px);
  padding-left: rem(48px);
}
</style>
